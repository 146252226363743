import classnames from "classnames";
import { useState } from "react";
import { NavLink } from "react-router-dom";

const MenuLinksMobile = ({ className }: { className: string }) => {
  return (
    <ul className={className}>
      <li>
        <NavLink
          to="/"
          className={({ isActive, isPending }) =>
            `px-4 py-2 text-lg font-bold w-full inline-block ${
              isActive
                ? "text-white bg-primary hover:text-white"
                : "text-dark hover:text-dark"
            }`
          }
        >
          Accueil
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/functionality"
          className={({ isActive, isPending }) =>
            `px-4 py-2 text-lg font-bold hover:text-dark w-full inline-block ${
              isActive
                ? "text-white bg-primary hover:text-white"
                : "text-dark hover:text-dark"
            }`
          }
        >
          Fonctionnalités
        </NavLink>
      </li>
    </ul>
  );
};

const MenuLinks = ({ className }: { className: string }) => {
  return (
    <ul className={className}>
      <li>
        <NavLink
          to="/"
          className={({ isActive, isPending }) =>
            `px-4 py-2 text-lg font-bold  ${
              isActive
                ? "text-white bg-primary rounded-xl hover:text-white"
                : "text-dark hover:text-dark"
            }`
          }
        >
          Accueil
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/functionality"
          className={({ isActive, isPending }) =>
            `px-4 py-2 text-lg font-bold hover:text-dark ${
              isActive
                ? "text-white bg-primary rounded-xl hover:text-white"
                : "text-dark hover:text-dark"
            }`
          }
        >
          Fonctionnalités
        </NavLink>
      </li>
    </ul>
  );
};

export default function Header() {
  const [isVisible, setIsvisble] = useState(false);
  return (
    <header className={classnames("bg-white sticky top-0 z-30 w-full sm:px-4")}>
      <div className="flex items-center justify-between mx-auto max-w-7xl px-2 py-2">
        <a className="flex flex-row space-x-2" href="#">
          <img
            className="w-10 h-10 rounded-xl"
            alt="keno4you"
            src="/img/assets/icon.webp"
          />
          <div className="flex flex-col">
            <h1 className="text-3xl font-bold">Keno4you</h1>
          </div>
        </a>
        <div className="flex flex-col md:flex-row items-center space-x-1">
          <MenuLinks className="hidden space-x-2 md:inline-flex" />
          <div className="inline-flex md:hidden">
            <button
              onClick={() => setIsvisble((prev) => !prev)}
              className="flex-none px-2 "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 8h16M4 16h16"
                />
              </svg>
              <span className="sr-only">Open Menu</span>
            </button>
          </div>
        </div>
      </div>
      {isVisible && (
        <div className="md:hidden w-full">
          <MenuLinksMobile className="flex flex-col" />
        </div>
      )}
    </header>
  );
}
