export default function Presentation() {
  return (
    <div className="h-screen w-full flex flex-col items-center justify-center">
      <div className="px-10 flex flex-col md:flex-row md:max-w-6xl md:justify-between z-50">
        <div className="flex flex-col items-start justify-center lg:pt-[88px] w-1/2">
          <div className="flex flex-col space-y-5">
            <h1 className="text-5xl font-bold">
              Statistiques et pronostics du Keno
            </h1>
            <p className="text-medium">
              Keno4you propose chaque jour les statistiques et pronostics de
              combinaison à jouer au Keno
            </p>
            <div className="flex flex-row space-x-2 w-full">
              <a
                href="#howItWorks"
                className="hover:text-dark bg-primary p-5 text-white rounded-md flex flex-row items-center justify-center space-x-5"
              >
                <p className="font-bold text-xl">Explorer l'application</p>
              </a>
            </div>
          </div>
        </div>
        <div className="hidden md:visible md:flex md:flex-col justify-center items-center w-1/2">
          <img alt="presentation" src="/img/assets/presentation.webp"></img>
        </div>
      </div>
      <div
        className="hidden lg:block absolute h-full w-1/2 right-0 bg-gradient-to-tr from-primary to-secondary z-0"
        style={{ borderRadius: "41% 0% 100% 66% / 84% 10% 57% 100%" }}
      ></div>
    </div>
  );
}
