import Download from "../Sections/Download";
import HowItWorks from "../Sections/HowItWorks";
import NumberOverView from "../Sections/NumberOverView";
import Presentation from "../Sections/Presentation";

export default function Home() {
  return (
    <>
      <section className="relative z-0">
        <Presentation />
      </section>
      <section id="howItWorks" className="relative z-0">
        <HowItWorks />
      </section>
      <section className="relative z-0">
        <NumberOverView />
      </section>
      <section className="relative z-0">
        <Download />
      </section>
    </>
  );
}
