export default function NumberOverView() {
  return (
    <div className="h-screen flex flex-col bg-dark">
      <div className="h-full w-full flex flex-col justify-between items-center">
        <div className="h-24 bg-white rounded-b-full w-full"></div>
        <div className="flex flex-col md:flex-row justify-center space-x-5 items-center px-10 md:px-32 w-full md:max-w-6xl">
          <div className="flex flex-col justify-center h-full md:py-10">
            <div className="flex flex-col items-center md:items-end space-y-2 text-center md:text-right">
              <p className="text-5xl font-bold text-white">971 000</p>
              <p className="text-white text-xs md:w-1/2">
                Plus de 971 000 pronostics générés par notre algorithme
              </p>
            </div>
          </div>
          <img
            alt="splashscreen"
            className="hidden md:block shadow-2xl"
            src="/img/assets/splashscreen.webp"
          ></img>
          <div className="flex flex-col justify-center h-full py-10">
            <div className="flex flex-col items-center md:items-start space-y-2 text-center md:text-left">
              <p className="text-5xl font-bold text-white">1 350 525 €</p>
              <p className="text-white text-xs md:w-1/2">
                Plus de 1 350 525 € générés en ayant joué tous les pronostics
                proposés
              </p>
            </div>
          </div>
        </div>
        <div className="h-24 bg-white rounded-t-full w-full"></div>
      </div>
    </div>
  );
}
