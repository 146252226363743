export default function SponsorShip() {
  return (
    <div className="h-screen w-full flex flex-col items-center justify-center">
      <div className="px-10 flex flex-row max-w-6xl justify-between z-50">
        <div className="flex flex-col items-center lg:items-start justify-center w-full lg:w-1/2">
          <div className="flex flex-col space-y-5">
            <img
              className="lg:hidden w-96 rotate-45"
              alt="Pronostics"
              src="/img/assets/pronostics_functionality.webp"
            ></img>
            <p className="text-5xl font-bold">Parrainage</p>
            <p className="text-medium">
              Parrainez des utilisateurs lors de leur inscription. Pour chaque
              personne parrainée, si un abonnement est souscrit ou renouvelé,
              vous toucherez des commissions qui pourront être transférées sur
              votre compte bancaire.
            </p>
          </div>
        </div>
        <div className="hidden lg:flex flex-col justify-center items-center w-1/2">
          <img
            className="w-96 rotate-45"
            alt="Pronostics"
            src="/img/assets/pronostics_functionality.webp"
          ></img>
        </div>
      </div>
      <div
        className="hidden lg:block absolute h-full w-2/5 right-0 bg-gradient-to-tr from-primary to-secondary z-0"
        style={{ borderRadius: "60% 100% 100% 79% / 68% 88% 90% 100%" }}
      ></div>
    </div>
  );
}
