export default function PronosticsDetail() {
  return (
    <div className="bg-primary lg:bg-white h-screen w-full flex flex-col items-center justify-center">
      <div className="px-10 flex flex-row max-w-6xl justify-between z-50">
        <div className="flex flex-col items-center lg:items-start justify-center w-full lg:w-1/2">
          <div className="flex flex-col space-y-5">
            <h2 className="text-3xl font-bold">Détails du pronostic</h2>
            <div className="p-10 rounded-xl bg-white shadow-xl">
              <p className="text-medium">
                Chaque pronostic bénéficie de leur propre analyse. Vous y
                trouverez la moyenne de sortie par tirage, le nombre de sorties
                de la combinaison en 3/4 et en 4/4, le gain généré par la
                combinaison sur les 1460 derniers tirages, l'écart et le mois de
                récurence.
              </p>
              <br />
              <p className="text-medium">
                L'historique vous permet de visualiser les gains au fil des mois
                ainsi que les numéros tirés au sort au cours des derniers
                tirages.
              </p>
            </div>
          </div>
        </div>
        <div className="hidden lg:flex lg:flex-col justify-center items-center w-1/2">
          <img
            className="w-72"
            alt="Détail Pronostics"
            src="/img/assets/pronostics_detail.webp"
          ></img>
        </div>
      </div>
    </div>
  );
}
