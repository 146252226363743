export default function Download() {
  return (
    <div className="h-screen w-full flex flex-col items-center justify-center">
      <div className="px-10 flex md:flex-row-reverse md:max-w-6xl justify-between z-50">
        <div className="flex flex-col items-start justify-center w-1/2">
          <div className="flex flex-col space-y-5">
            <h2 className="text-5xl font-bold">Téléchargement</h2>
            <p className="text-medium">
              L'application est disponible gratuitement sur le Play Store et
              l'App Store. Vous pouvez la télécharger ci-dessous.
            </p>
            <div className="flex flex-row space-x-2">
              <a
                href="https://apps.apple.com/fr/app/keno4you/id1597587644"
                target="_blank"
                className="hover:text-white hover:bg-dark bg-primary p-2 text-white rounded-md flex flex-row space-x-5"
              >
                <div>
                  <i className="text-4xl fa-brands fa-app-store"></i>
                </div>
                <div>
                  <p className="text-xs">Télécharger</p>
                  <p className="font-bold text-xl">App Store</p>
                </div>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.keno4you.app"
                target="_blank"
                className="hover:text-dark hover:border-dark border-primary text-primary border-2 p-2 rounded-md flex flex-row space-x-5"
              >
                <div>
                  <i className="text-4xl fa-brands fa-google-play"></i>
                </div>
                <div>
                  <p className="text-xs">Télécharger</p>
                  <p className="font-bold text-xl">Play Store</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="hidden md:visible md:flex md:flex-col justify-center items-center w-1/2">
          <img
            className="w-96"
            alt="download"
            src="/img/assets/download.webp"
          ></img>
        </div>
      </div>
      <div
        className="hidden lg:block absolute h-full w-2/5 left-0 bg-gradient-to-tr from-primary to-secondary z-0"
        style={{ borderRadius: "60% 100% 100% 79% / 68% 88% 90% 100%" }}
      ></div>
    </div>
  );
}
