import "antd/dist/antd.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import Functionality from "./pages/Functionality";
import Home from "./pages/Home";
import Layout from "./pages/Layout";

ReactDOM.createRoot(document.getElementById("root") as any).render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/functionality" element={<Functionality />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
