export default function Analyzer() {
  return (
    <div className="h-screen w-full flex flex-col items-center justify-center">
      <div className="px-10 flex flex-row-reverse max-w-6xl justify-between z-50">
        <div className="flex flex-col items-center lg:items-start justify-center w-full lg:w-1/2">
          <div className="flex flex-col space-y-5">
            <img
              className="lg:hidden w-96 rotate-12"
              alt="Analyser"
              src="/img/assets/analyzer_functionality.webp"
            ></img>
            <p className="text-5xl font-bold">Créez vos combinaisons</p>
            <p className="text-medium">
              Créez vos propres combinaisons en choisissant un écart minimum et
              une base de trois numéros. L'application vous donnera la liste des
              pronostics disponibles pour la base de jeu que vous avez définie.
            </p>
          </div>
        </div>
        <div className="hidden lg:flex flex-col justify-center items-center w-1/2">
          <img
            className="w-96 rotate-12"
            alt="Analyser"
            src="/img/assets/analyzer_functionality.webp"
          ></img>
        </div>
      </div>
      <div
        className="hidden lg:block absolute h-full w-2/5 left-0 bg-gradient-to-tr from-primary to-secondary z-0"
        style={{ borderRadius: "60% 100% 100% 79% / 68% 88% 90% 100%" }}
      ></div>
    </div>
  );
}
