export default function Wallet() {
  return (
    <div className="h-screen w-full flex flex-col items-center justify-center">
      <div className="px-10 flex flex-row-reverse max-w-6xl justify-between z-50">
        <div className="flex flex-col items-center lg:items-start justify-center w-full lg:w-1/2">
          <div className="flex flex-col space-y-5">
            <img
              className="lg:hidden w-96 -rotate-45"
              alt="Pronostics"
              src="/img/assets/wallet_functionality.webp"
            ></img>
            <p className="text-5xl font-bold">Portefeuille</p>
            <p className="text-medium">
              Le portefeuille recense toutes les combinaisons que vous souhaitez
              jouer. Vous verrez un aperçu de toutes les combinaisons ainsi que
              les résultats des deux derniers tirages pour chaque combinaison.
            </p>
            <br />
            <p className="text-medium">
              Les gains affichés sont des gains virtuels se basant sur le fait
              que vous avez respecté les suggestions de mise et que vous ayez
              joué la combinaison pour chaque tirage à partir de la date d'ajout
              de la combinaison dans le portefeuille.
            </p>
          </div>
        </div>
        <div className="hidden lg:flex lg:flex-col justify-center items-center w-1/2">
          <img
            className="w-96 -rotate-45"
            alt="Pronostics"
            src="/img/assets/wallet_functionality.webp"
          ></img>
        </div>
      </div>
      <div
        className="hidden lg:block absolute h-full w-2/5 left-0 bg-gradient-to-tr from-primary to-secondary z-0"
        style={{ borderRadius: "60% 100% 100% 79% / 68% 88% 90% 100%" }}
      ></div>
    </div>
  );
}
