export default function Footer() {
  const apiUrl = process.env.REACT_APP_API_CLIENT_URL;
  return (
    <div className="flex flex-col w-full lg:space-y-10 px-32 py-20 bg-white-shade items-center">
      <div className="flex flex-col lg:flex-row w-full justify-between max-w-5xl space-y-10 lg:space-y-0">
        <div className="flex flex-col space-y-10 md:w-1/3">
          <div className="flex flex-col space-y-2">
            <h4 className="font-bold text-xl">À propos</h4>
            <p className="text-medium text-xs">
              Application de statistiques et pronostics du Kéno
            </p>
            <br />
            <p className="text-medium text-xs">
              Jouer comporte des risques : endettement, isolement, dépendance.
              Pour être aidé, appelez le 09 74 75 13 13 (appel non surtaxé).
              Jeux déconseillés aux moins de 18 ans. Certaines fonctionnalités
              nécessite un abonnement mensuel ou annuel, voir les CGV / CGU de
              l'application
            </p>
          </div>
        </div>
        <div className="flex flex-col space-y-5">
          <h4 className="font-bold text-xl">Légales</h4>
          <a
            className="hover:text-primary"
            href={`https://api.keno4you.fr/client/v1.0/privacy`}
            target="_blank"
          >
            Confidentialités
          </a>
          <a
            className="hover:text-primary"
            href={`https://api.keno4you.fr/client/v1.0/cgu`}
            target="_blank"
          >
            Conditions générales d'utilisation
          </a>
          <a
            className="hover:text-primary"
            href={`https://api.keno4you.fr/client/v1.0/cgv`}
            target="_blank"
          >
            Conditions générales de vente
          </a>
        </div>
        <div className="flex flex-col space-y-5">
          <h4 className="font-bold text-xl">Assistance</h4>
          <a
            className="hover:text-primary"
            href={`https://api.keno4you.fr/client/v1.0/faq`}
            target="_blank"
          >
            Foire aux questions
          </a>
        </div>
        <div className="flex flex-col space-y-5">
          <h4 className="font-bold text-xl">Société</h4>
          <div>
            <p>SARL LF</p>
            <p>16 rue des étudiants</p>
            <p>67120 Molsheim</p>
          </div>
          <div className="flex flex-row space-x-2 items-center">
            <i className="fa-solid fa-envelope"></i>
            <p>contact@keno4you.fr</p>
          </div>
        </div>
      </div>
      <div className="flex flex-row w-full items-center justify-center border-t-2 space-x-10 border-medium-light pt-5">
        <a href="https://www.facebook.com/groups/keno4you" target="_blank">
          <i className="hover:text-primary text-dark text-4xl fa-brands fa-facebook"></i>
        </a>
        <a href="https://twitter.com/Keno4You" target="_blank">
          <i className="hover:text-primary text-dark text-4xl fa-brands fa-twitter"></i>
        </a>
        <a
          href="https://instagram.com/keno4you?igshid=YmMyMTA2M2Y="
          target="_blank"
        >
          <i className="hover:text-primary  text-dark text-4xl fa-brands fa-instagram"></i>
        </a>
      </div>
    </div>
  );
}
