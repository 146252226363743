import { Carousel } from "antd";

export default function HowItWorks() {
  return (
    <>
      <div className="block md:hidden">
        <div className="flex flex-col justify-center items-center">
          <h2 className="text-2xl md:text-3xl font-bold">
            Comment cela fonctionne ?
          </h2>
          <div className="w-1/2">
            <p className="text-medium text-center">
              Nous analysons les combinaisons sur un nombre de tirage prédéfini,
              afin de vous proposer les meilleurs pronostics possible
            </p>
          </div>
        </div>
        <Carousel className="w-full" dots={false} autoplay>
          <div>
            <div className="flex flex-col items-center justify-center w-full space-y-2 p-20">
              <img
                alt="pronostics"
                src="/img/assets/pronostics.webp"
                className="w-28"
              ></img>
              <h3 className="text-xl font-bold text-center">Pronostics</h3>
              <p className="text-medium text-center">
                Tous les jours recevez des pronostics de combinaison à jouer
              </p>
            </div>
          </div>
          <div>
            <div className="flex flex-col items-center justify-center space-y-2 p-20">
              <img
                alt="wallet"
                src="/img/assets/wallet.webp"
                className="w-28"
              ></img>
              <h3 className="text-xl font-bold text-center">Portefeuille</h3>
              <p className="text-medium text-center">
                Ajouter une ou plusieurs combinaison dans votre portefeuille
              </p>
            </div>
          </div>
          <div>
            <div className="flex flex-col items-center justify-center space-y-2 p-20">
              <img
                alt="suggestion"
                src="/img/assets/suggestion.webp"
                className="w-28"
              ></img>
              <h3 className="text-xl font-bold text-center">Suggestion</h3>
              <p className="text-medium text-center">
                Suivez les mises suggéré et tentez de récupérer au minimum votre
                mise en cas de sortie de la combinaison
              </p>
            </div>
          </div>
        </Carousel>
      </div>
      <div className="hidden md:h-screen md:flex md:flex-col md:justify-center md:w-full md:items-center">
        <div className="flex flex-col max-w-6xl space-y-10 px-10">
          <div className="flex flex-col justify-center items-center">
            <h2 className="text-2xl md:text-3xl font-bold">
              Comment cela fonctionne ?
            </h2>
            <div className="w-1/2">
              <p className="text-medium text-center">
                Nous analysons les combinaisons sur un nombre de tirage
                prédéfini, afin de vous proposer les meilleurs pronostics
                possible.
              </p>
              <br />
              <p className="text-center">
                <a
                  href="https://youtu.be/FDRlc5n2y50"
                  target="_blank"
                  className="text-primary font-bold hover:text-dark text-center"
                >
                  Vous pouvez visualiser le tutoriel de l'application Keno4you
                </a>
              </p>
            </div>
          </div>
          <div className="bg-white drop-shadow-lg flex flex-col p-10 rounded-md">
            <div className="flex flex-row justify-between w-full pb-5">
              <div className="flex flex-row items-center justify-center w-1/3">
                <img
                  alt="pronostics"
                  src="/img/assets/pronostics.webp"
                  className="w-28"
                ></img>
              </div>
              <div className="flex flex-row items-center justify-center w-1/3">
                <img
                  alt="wallet"
                  src="/img/assets/wallet.webp"
                  className="w-28"
                ></img>
              </div>
              <div className="flex flex-row items-center justify-center w-1/3">
                <img
                  alt="suggestion"
                  src="/img/assets/suggestion.webp"
                  className="w-28"
                ></img>
              </div>
            </div>
            <div className="flex flex-row justify-between w-full">
              <div className="w-1/3">
                <h3 className="text-xl font-bold text-center">Pronostics</h3>
              </div>
              <div className="w-1/3">
                <h3 className="text-xl font-bold text-center">Portefeuille</h3>
              </div>
              <div className="w-1/3">
                <h3 className="text-xl font-bold text-center">Suggestion</h3>
              </div>
            </div>
            <div className="flex flex-row justify-between w-full">
              <div className="w-1/3 px-10">
                <p className="text-medium text-center">
                  Recevez tous les jours des pronostics de combinaison à jouer
                </p>
              </div>
              <div className="w-1/3 px-10">
                <p className="text-medium text-center">
                  Ajoutez une ou plusieurs combinaisons dans votre portefeuille
                </p>
              </div>
              <div className="w-1/3 px-10">
                <p className="text-medium text-center">
                  Suivez les mises suggérées et tentez de récupérer au minimum
                  votre mise en cas de sortie de la combinaison
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
