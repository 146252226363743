export default function WalletDetail() {
  return (
    <div className="bg-primary lg:bg-white h-screen w-full flex flex-col items-center justify-center">
      <div className="px-10 flex flex-row max-w-6xl justify-between z-50">
        <div className="flex flex-col items-center lg:items-start justify-center w-full lg:w-1/2">
          <div className="flex flex-col space-y-5">
            <h2 className="text-3xl font-bold">Suggestion de mise</h2>
            <div className="p-10 rounded-xl bg-white shadow-xl">
              <p className="text-medium">
                Avant chaque tirage, l'application vous suggère pour chaque
                combinaison de votre portefeuille, la mise à jouer avec l'option
                multiplicateur.
              </p>
              <br />
              <p className="text-medium">
                Selon votre méthode de jeux choisie (Risky ou Safety) les gains
                et mise peuvent être plus ou moins importante. En suivant notre
                algorithme, si la combinaison est tiré au sort, vous récupérez
                au minimum votre mise initiale.
              </p>
              <br />
            </div>
          </div>
        </div>
        <div className="hidden lg:flex flex-col justify-center items-center w-1/2">
          <img
            className="w-72"
            alt="Détail Portefeuille"
            src="/img/assets/wallet_detail.webp"
          ></img>
        </div>
      </div>
    </div>
  );
}
