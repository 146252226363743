import ReactFullpage from "@fullpage/react-fullpage";
import Footer from "../components/Footer";
import Analyzer from "../Sections/Analyzer";
import Pronostics from "../Sections/Pronostics";
import PronosticsDetail from "../Sections/PronosticsDetail";
import SponsorShip from "../Sections/Sponsorship";
import Wallet from "../Sections/Wallet";
import WalletDetail from "../Sections/WalletDetail";

export default function Functionality() {
  return (
    <>
      <section className="relative z-0">
        <Pronostics />
      </section>
      <section className="relative z-0">
        <PronosticsDetail />
      </section>
      <section className="relative z-0">
        <Wallet />
      </section>
      <section className="relative z-0">
        <WalletDetail />
      </section>
      <section className="relative z-0">
        <Analyzer />
      </section>
      <section className="relative z-0">
        <SponsorShip />
      </section>
    </>
  );
}
